import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@env';
import { AddFriendInput } from '@interfaces/inputs/add-friend-input.interface';
import { AddressInput } from '@interfaces/inputs/address-input.interface';
import { ChangePasswordInput } from '@interfaces/inputs/change-password-input.interface';
import { ConfigurationInput } from '@interfaces/inputs/configuration-input.interface';
import { NewPasswordInput } from '@interfaces/inputs/new-password-input.interface';
import { RedeemVoucherInput } from '@interfaces/inputs/redeem-voucher-input.interface';
import { ResetPasswordInput } from '@interfaces/inputs/reset-password-input.interface';
import { SignUpInput } from '@interfaces/inputs/sign-up-input.interface';
import { UpdateUserInput } from '@interfaces/inputs/update-user-input.interface';
import { VerifyInput } from '@interfaces/inputs/verify-input.interface';
import { ChangePasswordResponse } from '@interfaces/responses/change-password-response.interface';
import { DataResponse } from '@interfaces/responses/data-response.interface';
import { NewPasswordResponse } from '@interfaces/responses/new-password-response.interface';
import { RedeemVoucherResponse } from '@interfaces/responses/redeem-voucher-response.interface';
import { ResetPasswordResponse } from '@interfaces/responses/reset-password-response.interface';
import { SendPointsResponse } from '@interfaces/responses/send-points-response.interface';
import { SignUpResponse } from '@interfaces/responses/sign-up-response.interface';
import { UpdateUserResponse } from '@interfaces/responses/update-user-response.interface';
import { VerifyResponse } from '@interfaces/responses/verify-response.interface';
import { Address } from '@models/address.model';
import { Friend } from '@models/friend.model';
import { User } from '@models/user.model';
import { Observable, map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserService {
  private readonly http = inject(HttpClient);

  /**
   * Sign up function
   */
  signUp(data: SignUpInput): Observable<SignUpResponse> {
    return this.http.post(environment.api + 'users', data).pipe(map(response => response as SignUpResponse));
  }

  /**
   * Activate account via SMS
   */
  verify(input: VerifyInput): Observable<VerifyResponse> {
    return this.http
      .post(environment.api + `users_auth_code/${input.id}?auth_code=${input.auth_code}`, {})
      .pipe(map(response => response as VerifyResponse));
  }

  /**
   * Activate account via SMS for social media users
   */
  verifyAuthCode(input: VerifyInput): Observable<VerifyResponse> {
    return this.http
      .post(environment.api + `users_auth_code/${input.id}`, { auth_code: input.auth_code })
      .pipe(map(response => response as VerifyResponse));
  }

  /**
   * Resend SMS code
   */
  resendSmsCode(id: string): Observable<VerifyResponse> {
    return this.http
      .post(environment.api + `users_auth_code_again/${id}`, {})
      .pipe(map(response => response as VerifyResponse));
  }

  /**
   * Remove account permanently
   */
  deleteUser(id: string): Observable<any> {
    return this.http.delete(environment.api + `users/${id}`).pipe(map(response => response as any));
  }

  /**
   * Reset password
   */
  resetPassword(input: ResetPasswordInput): Observable<ResetPasswordResponse> {
    return this.http
      .post(environment.api + `reset-password`, input)
      .pipe(map(response => response as ResetPasswordResponse));
  }

  /**
   * Set new password
   */
  setNewPassword(input: NewPasswordInput): Observable<NewPasswordResponse> {
    return this.http
      .put(environment.api + `reset-password`, input)
      .pipe(map(response => response as NewPasswordResponse));
  }

  /**
   * Changes the password for the user.
   * @param input - The input containing the new password.
   * @returns An Observable that emits the response after the password change.
   */
  changePassword(input: ChangePasswordInput): Observable<ChangePasswordResponse> {
    return this.http
      .put(environment.api + `change-password`, input)
      .pipe(map(response => response as ChangePasswordResponse));
  }

  /**
   * Get user addresses
   */
  getAddresses(): Observable<DataResponse<Address[]>> {
    return this.http.get(environment.api + 'addresses').pipe(map(response => response as DataResponse<Address[]>));
  }

  /**
   * Retrieves the current user information.
   * @returns An Observable that emits a DataResponse containing the user information.
   */
  getMe(): Observable<DataResponse<User>> {
    return this.http.get(environment.api + 'users/me').pipe(map(response => response as DataResponse<User>));
  }

  /**
   * Update user
   */
  updateUser(id: string, input: UpdateUserInput): Observable<UpdateUserResponse> {
    return this.http.put(environment.api + `users/${id}`, input).pipe(map(response => response as UpdateUserResponse));
  }

  /**
   * Set phone to not activated user
   */
  setPhone(input: ConfigurationInput): Observable<unknown> {
    return this.http.post(environment.api + `users/set-phone`, input).pipe(map(response => response as unknown));
  }

  /**
   * Get user addresses
   */
  addAddress(input: AddressInput): Observable<DataResponse<Address>> {
    return this.http
      .post(environment.api + 'addresses', input)
      .pipe(map(response => response as DataResponse<Address>));
  }

  /**
   * Update user address
   */
  updateAddress(id: string, input: AddressInput): Observable<DataResponse<Address>> {
    return this.http
      .put(environment.api + `addresses/${id}`, input)
      .pipe(map(response => response as DataResponse<Address>));
  }

  /**
   * Get particular address
   */
  getAddress(id: string): Observable<DataResponse<Address>> {
    return this.http.get(environment.api + `addresses/${id}`).pipe(map(response => response as DataResponse<Address>));
  }

  /**
   * Remove address permanently
   */
  deleteAddress(id: number): Observable<DataResponse<Address>> {
    return this.http
      .delete(environment.api + `addresses/${id}`)
      .pipe(map(response => response as DataResponse<Address>));
  }

  /**
   * Add friend
   */
  addFriend(input: AddFriendInput): Observable<DataResponse<Friend>> {
    return this.http.post(environment.api + 'friends', input).pipe(map(response => response as DataResponse<Friend>));
  }

  /**
   * Get user friends
   */
  getFriends(): Observable<DataResponse<Friend[]>> {
    return this.http.get(environment.api + `friends`).pipe(map(response => response as DataResponse<Friend[]>));
  }

  /**
   * Accept friend invitation
   */
  acceptInvitation(id: number): Observable<DataResponse<Friend>> {
    return this.http
      .put(environment.api + `friends/${id}?status=1`, {})
      .pipe(map(response => response as DataResponse<Friend>));
  }

  /**
   * Decline friend
   */
  deleteFriend(id: number): Observable<DataResponse<Friend>> {
    return this.http.delete(environment.api + `friends/${id}`).pipe(map(response => response as DataResponse<Friend>));
  }

  /**
   * Send points to friend
   */
  sendPoints(id: number, amount: number): Observable<SendPointsResponse> {
    return this.http
      .get(environment.api + `send_points?amount=${amount}&receiverId=${id}`)
      .pipe(map(response => response as SendPointsResponse));
  }

  /**
   * Redeems a voucher.
   * @param input - The input data for redeeming the voucher.
   * @returns An observable that emits the response from the API call.
   */
  redeemVoucher(input: RedeemVoucherInput): Observable<RedeemVoucherResponse> {
    return this.http
      .post(environment.api + `vouchers/redeem`, input)
      .pipe(map(response => response as RedeemVoucherResponse));
  }
}
